import {
  extendTheme, ThemeConfig,
} from '@chakra-ui/react';
import '@fontsource/vollkorn';
import '@fontsource/lato';

// const extraColors = {
//   pink: [
//     '#3d1c29',
//     '#813355',
//     '#c54b81',
//     '#ff65aa',
//     '#ff82cc',
//     '#ffa0e4',
//     '#ffbdf3',
//     '#ffd7fb',
//     '#ffedfe',
//   ],
//   gray: [
//     '#272936',
//     '#3c3f53',
//     '#515671',
//     '#696e8d',
//     '#8187a7',
//     '#9ba0be',
//     '#b6b9d2',
//     '#d1d3e4',
//     '#ecedf4',
//   ],
//   cyan: [
//     '#0f1a19',
//     '#1d4d4b',
//     '#258380',
//     '#34b6b1',
//     '#50dfd9',
//     '#75fbf5',
//     '#9dffff',
//     '#c2ffff',
//     '#e3ffff',
//   ],
//   sage: [
//     '#161817',
//     '#3a4541',
//     '#61736d',
//     '#86a097',
//     '#a8c7bc',
//     '#c4e3d9',
//     '#d9f5ec',
//     '#e9fef7',
//     '#f4fffc',
//   ],
// };

type Theme = {
  config: ThemeConfig;
  fonts: {
    heading: string;
    body: string;
  };
  // colors: Colors;
};

const theme: Theme = {
  config: {
    useSystemColorMode: true,
  },
  fonts: {
    heading: 'Vollkorn',
    body: 'Lato',
  },
  // colors: Object.entries(extraColors).reduce<Colors>((acc, [color, colorStops]) => {
  //   const stops:string[] = colorStops;

  //   acc[color] = stops.reduce<Colors>((hues, hue, index) => {
  //     const hueNumber = (9 - index) * 100 as Partial<ColorHues>;
  //     // eslint-disable-next-line no-param-reassign
  //     hues[`${hueNumber}`] = hue;
  //     return hues;
  //   }, {});

  //   return acc;
  // }, {

  // }),
};

export default extendTheme(theme);
